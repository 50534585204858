import { NgModule } from '@angular/core';
import { RouterModule, Routes, NoPreloading } from '@angular/router';

const routes: Routes = [
    { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
    { path: 'buyers', loadChildren: () => import('./pages/buyers/buyers.module').then(m => m.BuyersPageModule) },
    { path: 'sellers', loadChildren: () => import('./pages/sellers/sellers.module').then(m => m.SellersPageModule) },
    { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule) },
    { path: 'help', loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule) },
    { path: 'browse', loadChildren: () => import('./pages/browse/browse.module').then(m => m.BrowsePageModule) },
    { path: 'product', loadChildren: () => import('./pages/product/product.module').then(m => m.ProductPageModule) },
    { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
    { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule) },
    {
        path: 'campaign',
        loadChildren: () => import('./pages/campaign-builder/campaign-builder.module').then(m => m.CampaignBuilderModule)
    },
    { path: 'signup', loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule) },
    { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartPageModule) },
    { path: 'elections', loadChildren: () => import('./pages/elections/elections.module').then(m => m.ElectionsPageModule) },
    { path: 'list', loadChildren: () => import('./pages/list/list.module').then(m => m.ListPageModule) },

    // This catch-all route always must come last, angular works on a first-match-wins basis
    { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
