import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { ActivateAccountCredentials } from '../../models/activate-account.model';
import { AuthCredentials } from '../../models/auth-credentials.model';
import { SessionService } from '../services/session.service';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class AuthApiService extends BaseApiService {
    resourceRoot = 'auth';

    constructor(protected sessionService: SessionService, private httpClient: HttpClient) {
        super();
    }

    login(authCredentials: AuthCredentials) {
        const url = this.buildUrl(this.resourceRoot + '/login');
        return this.httpClient
            .post<any>(url, authCredentials)
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    activate(authCredentials: ActivateAccountCredentials) {
        const url = this.buildUrl(this.resourceRoot + '/activate/' + authCredentials.activationCode);
        return this.httpClient
            .post<any>(url, authCredentials)
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    register(registerCredentials: any) {
        const url = this.buildUrl(this.resourceRoot + '/register');
        return this.httpClient
            .post<any>(url, registerCredentials)
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    refreshToken(token: string) {
        const url = this.buildUrl(this.resourceRoot + '/refresh');
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        return this.httpClient
            .post<any>(url, null, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    logout(token: string) {
        const url = this.buildUrl(this.resourceRoot + '/logout');
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        return this.httpClient
            .post<any>(url, null, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    updatePassword(token: string, data: any) {
        const url = this.buildUrl(this.resourceRoot + '/password/change');
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        return this.httpClient
            .put<any>(url, data, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }
}
