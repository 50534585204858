import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { SessionService } from '../services/session.service';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class ImpersonateApiService extends BaseApiService {
    resourceRoot = 'impersonate';

    constructor(protected sessionService: SessionService, private httpClient: HttpClient) {
        super();
    }

    take(token: string, userId: string) {
        const url = this.buildUrl(this.resourceRoot + '/take/' + userId);
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);
        return this.httpClient
            .get<any>(url, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    leave(token: string) {
        const url = this.buildUrl(this.resourceRoot + '/leave');
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);
        return this.httpClient
            .get<any>(url, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    info(token: string) {
        const url = this.buildUrl(this.resourceRoot + '/info');
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);
        return this.httpClient
            .get<any>(url, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }
}
