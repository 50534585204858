import { Injectable } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';

import { Cart } from '../../models/cart.model';
import { UserModel } from '../../models/user.model';
import { CartApiService } from '../api/cart.service';
import { CartLocalService } from '../local/cart.service';
import { SessionService } from './session.service';

@Injectable({ providedIn: 'root' })
export class CartService {
    tempItems = 0;
    cart$ = new BehaviorSubject<Cart | null>(null);

    constructor(
        private cartLocalService: CartLocalService,
        private cartApiService: CartApiService,
        private sessionService: SessionService
    ) {
        this.sessionService
            .watchUser()
            .pipe(untilDestroyed(this, 'destroy'))
            .subscribe(user => {
                if (typeof user === 'object' && user !== null) {
                    this.cartApiService
                        .getCartForUser(user.id, user.token)
                        .pipe(untilDestroyed(this, 'destroy'))
                        .subscribe(cartResponse => {
                            this.processAPIServiceResponse(cartResponse);
                        });
                } else {
                    this.cartLocalService.get('guest').then(response => {
                        this.processLocalServiceResponse(response);
                    });
                }
            });
    }

    destroy() {}

    public watchCart(): Observable<Cart | null> {
        return this.cart$;
    }

    public peekCart(): Cart | null {
        return this.cart$.value;
    }

    public pokeCart(cart: Cart | null): void {
        this.cart$.next(cart);
    }

    getFullListing(itemListingScheduleIds) {
        //returns an array of 'cart items' - just enough info to draw the cart
        return this.cartApiService
            .getListingInfoForCart(itemListingScheduleIds, 'guest')
            .toPromise<any>()
            .then(response => {
                return response;
            });
    }

    add(itemListingScheduleIds: any[]) {
        this.tempItems++;

        return this.sessionService
            .getCurrentUser()
            .then(user => {
                // Prepare a list of Ids to sync
                user = user as UserModel;
                return this.cartApiService
                    .addItemListingsForUser(user.id, itemListingScheduleIds, user.token)
                    .toPromise()
                    .then(response => {
                        this.tempItems--;
                        return this.processAPIServiceResponse(response);
                    });
            })
            .catch(err => {
                // If not logged in fetch listing info and add to local cart only.
                //TODO: remove this, just save items to local service

                return this.cartLocalService.add('guest', itemListingScheduleIds).then(response => {
                    this.tempItems--;
                    return this.processLocalServiceResponse(response);
                });
            });
    }

    remove(itemListingScheduleId: any) {
        return this.sessionService
            .getCurrentUser()
            .then(user => {
                user = user as UserModel;
                return this.cartApiService
                    .removeItemListingForUser(user.id, itemListingScheduleId, user.token)
                    .toPromise()
                    .then(response => {
                        return this.processAPIServiceResponse(response);
                    });
            })
            .catch(() => {
                // If not logged in fetch listing info and remove from local cart only.
                return this.cartLocalService.remove('guest', itemListingScheduleId).then(innerResponse => {
                    return this.processLocalServiceResponse(innerResponse);
                });
            });
    }

    protected createArrayOfItemListingIdsFromItemListings(itemListingSchedules: any[]) {
        // Prepare a list of Ids to sync
        const itemListingScheduleIds = [];
        for (const itemListingSchedule of itemListingSchedules) {
            itemListingScheduleIds.push(itemListingSchedule.id);
        }

        return itemListingScheduleIds;
    }

    processAPIServiceResponse(response) {
        const data = response.data;
        const itemListingSchedules = data.contents;
        const updated_at_string = data.updated_at;
        const cart = new Cart(itemListingSchedules, updated_at_string);
        this.set(cart);
        return cart;
    }

    processLocalServiceResponse(response) {
        const cart = this.convertLocalResponseToCart(response);
        this.set(cart);
        return cart;
    }

    private convertLocalResponseToCart(response) {
        if (!response.guest || !response.guest.itemListingSchedules || !response.guest.itemListingSchedules.data) {
            this.cartLocalService.emptyCart('guest').then(emptyCart => {
                return emptyCart;
            });
        } else {
            const updated_at_string = String(Date.now());
            return new Cart(response.guest.itemListingSchedules.data, updated_at_string);
        }
    }

    syncItemListings(itemListingScheduleIds: any[]) {
        return this.sessionService
            .getCurrentUser()
            .then(user => {
                // Prepare a list of Ids to sync
                user = user as UserModel;
                return this.cartApiService
                    .syncItemListingsForUser(user.id, itemListingScheduleIds, user.token)
                    .toPromise()
                    .then(response => {
                        return this.processAPIServiceResponse(response);
                    });
            })
            .catch(() => {
                return this.cartLocalService.sync('guest', itemListingScheduleIds).then(response => {
                    return this.processLocalServiceResponse(response);
                });
            });
    }

    getNumberOfItemsInMemory() {
        const cart = this.peekCart();
        if (cart === null) {
            return 0;
        }
        return cart.itemListingSchedules.data.length + this.tempItems;
    }

    set(cart: Cart) {
        this.pokeCart(cart);
    }

    checkItemListingScheduleIdExistsInUsersCart(itemListingScheduleId: number) {
        const cart = this.peekCart();
        if (cart === null) {
            return false;
        }

        let exists = false;
        const itemListingSchedules = cart.itemListingSchedules.data;

        for (const existingItemListingSchedule of itemListingSchedules) {
            if (existingItemListingSchedule.id === itemListingScheduleId) {
                exists = true;
                break;
            }
        }

        return exists;
    }

    importLocalStorageCartDataToUsersCart() {
        const guest = 'guest';

        return this.cartLocalService.get(guest).then(response => {
            const cart = this.convertLocalResponseToCart(response);

            // Check if the local cart has any objects.
            if (!cart || cart.itemListingSchedules.data.length === 0) {
                return;
            }

            // Get the ItemListings and pass to the users cart
            const itemListingSchedules = cart.itemListingSchedules.data;

            // Add the guest/local users cart
            this.add(itemListingSchedules).then(innerResponse => {
                // Empty the local cart
                this.cartLocalService.emptyCart(guest);
            });
        });
    }
}
