import { Inject, Injectable, Optional, PLATFORM_ID, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { parse, Renderer } from 'marked';

import { MarkedOptions } from './marked-options';

@Injectable({ providedIn: 'root' })
export class MarkdownService {
    get renderer(): Renderer {
        return this.options.renderer;
    }
    set renderer(value: marked.Renderer) {
        this.options.renderer = value;
    }

    constructor(@Inject(PLATFORM_ID) private platform: Object, private domSanitizer: DomSanitizer, public options: MarkedOptions) {
        if (!this.renderer) {
            this.renderer = new Renderer();
        }
    }

    compile(markdown: string, decodeHtml = false, markedOptions = this.options): string {
        const precompiled = this.precompile(markdown);
        const compiled = parse(precompiled, markedOptions);
        return markedOptions.sanitize && !markedOptions.sanitizer ? this.domSanitizer.sanitize(SecurityContext.HTML, compiled) : compiled;
    }

    private precompile(markdown: string): string {
        if (!markdown) {
            return '';
        }
        let indentStart: number;
        return markdown
            .split('\n')
            .map(line => {
                // set current line ident start to base reference indentation
                let lineIdentStart = indentStart;
                // find position of 1st non-whitespace character
                // to determine the current line indentation start
                if (line.length > 0) {
                    lineIdentStart = isNaN(lineIdentStart) ? line.search(/\S|$/) : Math.min(line.search(/\S|$/), lineIdentStart);
                }
                // keep 1st non-whitespace line indentation
                // as base reference for other lines
                if (isNaN(indentStart)) {
                    indentStart = lineIdentStart;
                }
                // remove whitespaces before current line indentation
                return !!lineIdentStart ? line.substring(lineIdentStart) : line;
            })
            .join('\n');
    }
}
