import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { SessionService } from '../services/session.service';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class WishlistApiService extends BaseApiService {
    resourceRoot = 'user';

    constructor(protected sessionService: SessionService, private httpClient: HttpClient) {
        super();
    }

    getUserWishlists(userId: number, token: string) {
        const url = this.buildUrl(this.resourceRoot + '/' + userId + '/wishlist');
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        // Gets all products and modifies the responses for UI
        return this.httpClient
            .get(url, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    getWishlistByIdForUser(userId: number, listId: number, token: string) {
        const url = this.buildUrl(this.resourceRoot + '/' + userId + '/wishlist/' + listId);
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        return this.httpClient
            .get(url, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    setListShareType(userId: number, listId: number, token: string, share_type: string = 'private') {
        const url = this.buildUrl(this.resourceRoot + '/' + userId + '/wishlist/' + listId + '/share');
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        // Gets all products and modifies the responses for UI
        return this.httpClient
            .post(url, { share_type: share_type }, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    getBigWishlistForUser(userId: number, token: string) {
        const url = this.buildUrl(this.resourceRoot + '/' + userId + '/wishlist-big');
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        // Gets all products and modifies the responses for UI
        return this.httpClient
            .get(url, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    getBigWishlistBySlug(slug: string, token: string = null) {
        const url = this.buildUrl('wishlist/' + slug);
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        return this.httpClient
            .get(url, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    addWishlistForUser(userId: number, data: any, token: string) {
        const url = this.buildUrl(this.resourceRoot + '/' + userId + '/wishlist');
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        // Post the wishlist info
        return this.httpClient
            .post(url, data, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    updateWishlistForUser(userId: number, listId: number, data: any, token: string) {
        const url = this.buildUrl(this.resourceRoot + '/' + userId + '/wishlist/' + listId);
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        // Post the wishlist info
        return this.httpClient
            .put(url, data, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    addItemForUser(userId: number, listId: number, itemId: number, type: string, token: string) {
        const url = this.buildUrl(this.resourceRoot + '/' + userId + '/wishlist/' + listId + '/add');
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        // Prepare the JSON body
        const body = {
            id: itemId,
            type: type
        };

        // Puts/Syncs the itemListing Ids to the wishlist
        return this.httpClient
            .post(url, body, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    removeItemForUser(userId: number, listId: number, itemId: number, type: string, token: string) {
        const url = this.buildUrl(this.resourceRoot + '/' + userId + '/wishlist/' + listId + '/remove');
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        // Prepare the JSON body
        const body = {
            id: itemId,
            type: type
        };

        // Deletes the itemListing Ids from the wishlist
        return this.httpClient
            .patch(url, body, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    deleteList(userId: number, listId: number, token: string = null) {
        let headers = this.initialiseHeaders();
        if (token) {
            // If has a token, add the token
            headers = this.addTokenToHeaders(headers, token);
        }

        const url = this.buildUrl(this.resourceRoot + '/' + userId + '/wishlist/' + listId);

        return this.httpClient
            .delete(url, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }
}
