import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

import { LocalService } from './local.service';
import { UserModel } from '../../models/user.model';

@Injectable({ providedIn: 'root' })
export class UsersLocalService extends LocalService {
    constructor(public storage: Storage, public httpClient: HttpClient) {
        super(storage, httpClient);
        this.key = 'user';
    }

    get(): Promise<UserModel> {
        return this.getValue().then(data => {
            return data as UserModel;
        });
    }

    set(user) {
        return this.setValue(user);
    }

    remove() {
        return this.removeKey();
    }
}
