import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { untilDestroyed } from 'ngx-take-until-destroy';
import 'foundation-sites';
import { SessionExpiredModalComponent } from './pages/modals/session-expired/session-expired-modal';
import { CartService } from './providers/services/cart.service';
import { CategoriesService } from './providers/services/categories.service';
import { RouterHistoryService } from './providers/services/router-history.service';
import { SessionService } from './providers/services/session.service';
import { WishlistService } from './providers/services/wishlist.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    showSessionExpiredModal = true; // Used to control whether the session expired modal should be shown

    constructor(
        private platform: Platform,
        private nav: NavController,
        public modalController: ModalController,
        private toastController: ToastController,
        private sessionService: SessionService,
        private wishlistService: WishlistService,
        private cartService: CartService,
        public categoriesService: CategoriesService,
        protected routerHistoryService: RouterHistoryService,
        private swUpdate: SwUpdate
    ) {
        this.platform.ready().then(() => {
            this.configureEventListeners();
            this.categoriesService.purgeAllStorage();
            this.categoriesService.preloadCategoriesIntoMemory();
        });
    }

    ngOnInit() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                this.toastController
                    .create({
                        message: 'A new version of adfomo is available. Refresh?',
                        position: 'bottom',
                        cssClass: 'info',
                        buttons: [
                            {
                                side: 'end',
                                text: '',
                                icon: 'refresh',
                                handler: () => {
                                    window.location.reload();
                                }
                            },
                            {
                                text: '',
                                role: 'cancel',
                                icon: 'close-circle'
                            }
                        ]
                    })
                    .then(toast => {
                        toast.present();
                    });
            });
        }
    }

    ngOnDestroy() {}

    fetchAllAvailableUserData() {
        this.sessionService.isLoggedIn();
    }

    configureEventListeners() {
        const app = this;

        this.sessionService
            .watchUser()
            .pipe(untilDestroyed(this))
            .subscribe(user => {
                if (typeof user === 'object') {
                    if (user !== null) {
                        // This is the case where a user has logged in (we just got a new value from the Observable and it was a UserModel)

                        // Ensure that subsequent USER_SESSION_EXPIRED events do present the modal
                        app.showSessionExpiredModal = true;

                        // If we were on the login page, work out where to navigate to. Back to the page we came from if possible,
                        // otherwise default to inventory management for sellers or browse for buyers.
                        if (this.routerHistoryService.currentUrl.indexOf('/login') !== -1) {
                            if (this.routerHistoryService.previousUrl !== '/login') {
                                app.nav.navigateForward(this.routerHistoryService.previousUrl);
                            } else if (user.company_type === 'seller') {
                                app.nav.navigateRoot('/account/manage-inventory');
                            } else {
                                app.nav.navigateRoot('/browse');
                            }
                        }

                        // Handle local cart data
                        app.cartService.importLocalStorageCartDataToUsersCart();
                    }
                } else {
                    // This is the case where a user has logged out (we got a new value from the Observable and it was a bool)

                    // Fetch the available user data i.e. Get the cart
                    app.fetchAllAvailableUserData();
                    const expired = user as boolean;
                    if (expired) {
                        // This was a session expiry, so show the modal if we need to
                        if (app.showSessionExpiredModal) {
                            app.showSessionExpiredModal = false;
                            app.modalController
                                .create({
                                    component: SessionExpiredModalComponent,
                                    componentProps: {
                                        enableBackdropDismiss: false
                                    }
                                })
                                .then(modal => {
                                    modal.present();
                                });
                        }
                    } else {
                        // This was a log out, set the nav root to the HomePage
                        app.nav.navigateRoot('/');
                    }
                }
            });
    }
}
