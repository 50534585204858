import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { TextMaskModule } from 'angular2-text-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MarkdownModule, MarkedRenderer } from './components/markdown/markdown.module';
import { MarkedOptions } from './components/markdown/marked-options';
import { RouterHistoryService } from './providers/services/router-history.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

export function markedOptionsFactory(): MarkedOptions {
    const renderer = new MarkedRenderer();

    // Don't allow images or links when parsing markdown
    renderer.image = (href: string, title: string, text: string) => {
        return text ? text : title;
    };
    renderer.link = (href: string, title: string, text: string) => {
        return text;
    };

    return {
        renderer: renderer,
        gfm: true,
        tables: true,
        breaks: true,
        pedantic: false,
        sanitize: true, // This strips HTML when parsing
        smartLists: true,
        smartypants: false,
        silent: true
    };
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            animated: false,
            rippleEffect: false,
            mode: 'md'
        }),
        AppRoutingModule,
        IonicStorageModule.forRoot({ name: '__adfomo_store', size: 2097152 }),
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        TextMaskModule,
        BrowserAnimationsModule,
        CreditCardDirectivesModule,
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MarkedOptions,
                useFactory: markedOptionsFactory
            }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.serviceWorker })
    ],
    providers: [StatusBar, SplashScreen, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, RouterHistoryService],
    bootstrap: [AppComponent]
})
export class AppModule {}
