import { Injectable } from '@angular/core';
import { LoadingController, NavController, ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';

@Injectable({ providedIn: 'root' })
// A service to ensure DRY protocol.
export class UserFeedbackService {
    public loading: HTMLIonLoadingElement;
    public toast: HTMLIonToastElement;

    constructor(public loadingCtrl: LoadingController, public toastCtrl: ToastController) {}

    async presentLoading(message: string, duration?: number) {
        this.loading = await this.loadingCtrl.create({ message: message, duration: duration });
        await this.loading.present();
        return this.loading;
    }

    dismissLoading() {
        if (this.loading) {
            this.loading.dismiss();
        }
    }

    presentToastSuccess(message: string, duration_long: boolean, show_close: boolean, position: ToastOptions['position'] = 'bottom') {
        this.presentToast(message, duration_long, show_close, position, 'success');
    }

    presentToastError(message: string, duration_long: boolean, show_close: boolean, position: ToastOptions['position'] = 'bottom') {
        this.presentToast(message, duration_long, show_close, position, 'error');
    }

    presentToastCatastrophe(error: any) {
        const message = 'Oh no! Something went wrong! Please try again, or let us know if this keeps happening';
        this.presentToast(message, true, true, 'bottom', 'error');
    }

    presentToastValidationErrors(errors: any) {
        let message = 'Oops! There was a problem with the following: \n \n';

        for (const errorType in errors) {
            if (!errors.hasOwnProperty(errorType)) continue;
            for (const errorMessage of errors[errorType]) {
                message += '<li>' + errorMessage + '</li>\n';
            }
        }
        this.presentToast(message, true, true, 'bottom', 'error');
    }

    presentToastContactUs(message: string, nav: NavController) {
        if (this.toast) {
            this.dismissToast();
        }

        this.toastCtrl
            .create({
                message: message,
                showCloseButton: true,
                closeButtonText: 'Contact Us',
                position: 'bottom',
                cssClass: 'error'
            })
            .then(toast => {
                this.toast = toast;
                this.toast.onDidDismiss().then(eventDetail => {
                    if (eventDetail.role === 'close') {
                        nav.navigateForward('/contact');
                    }
                });
                this.toast.present();
                return this.toast;
            });
    }

    protected presentToast(
        message: string,
        duration_long: boolean,
        show_close: boolean,
        position: ToastOptions['position'] = 'bottom',
        cssClass: string = ''
    ) {
        if (this.toast) {
            this.dismissToast();
        }

        this.toastCtrl
            .create({
                message: message,
                duration: duration_long ? 10000 : 3000,
                showCloseButton: show_close,
                closeButtonText: 'OK',
                position: position,
                cssClass: cssClass
            })
            .then(toast => {
                this.toast = toast;
                this.toast.present();
                return this.toast;
            });
    }

    dismissToast() {
        this.toast.dismiss();
    }
}
