export class Wishlist {
    id: number;
    title: string;
    items: any[];
    itemListings: any[];
    itemListingSchedules: any[];
    itemIds: number[];
    itemListingIds: number[];
    itemListingScheduleIds: number[];
    slug?: string;
    share_type?: string;
    start_date?: Date;
    end_date?: Date;
    updated_at: Date;
    is_active: boolean;
    user: any;

    constructor(
        id: number = null,
        title: string = 'My Campaign',
        items: any[] = [],
        itemListings: any[] = [],
        itemListingSchedules: any[] = [],
        itemIds: number[] = [],
        itemListingIds: number[] = [],
        itemListingScheduleIds: number[] = [],
        slug: string = null,
        share_type: string = null,
        start_date: Date = null,
        end_date: Date = null,
        updated_at: Date = null,
        is_active: boolean = true,
        user: any = null
    ) {
        this.id = id;
        this.title = title;
        this.items = items;
        this.itemListings = itemListings;
        this.itemListingSchedules = itemListingSchedules;
        this.itemIds = itemIds;
        this.itemListingIds = itemListingIds;
        this.itemListingScheduleIds = itemListingScheduleIds;
        this.slug = slug;
        this.share_type = share_type;
        this.start_date = start_date;
        this.end_date = end_date;
        this.updated_at = updated_at;
        this.is_active = is_active;
        this.user = user;
    }
}
