import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Injectable()
export class RouterHistoryService {
    previousUrl: string = undefined;
    currentUrl: string = undefined;

    constructor(protected router: Router) {
        this.currentUrl = this.router.url;

        this.router.events.subscribe((event: NavigationStart) => {
            if (event instanceof NavigationStart) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            }
        });
    }
}
