export class CategoryHelper {
    static getParentCategories() {
        return [
            { id: 1, name: 'TV' },
            { id: 6, name: 'Radio' },
            { id: 10, name: 'Print' },
            { id: 27, name: 'Outdoor' },
            { id: 49, name: 'Cinema' },
            { id: 54, name: 'Digital' },
            { id: 59, name: 'Sponsorship' }
        ];
    }

    static getBrowseLocations() {
        return [
            {
                locality: 'Sydney',
                capital: true,
                coordinates_latitude: -33.8688197,
                coordinates_longitude: 151.20929550000005
            },
            {
                locality: 'Melbourne',
                capital: true,
                coordinates_latitude: -37.8136276,
                coordinates_longitude: 144.96305759999996
            },
            {
                locality: 'Brisbane',
                capital: true,
                coordinates_latitude: -27.4697707,
                coordinates_longitude: 153.02512350000006
            },
            {
                locality: 'Perth',
                capital: true,
                coordinates_latitude: -31.9505269,
                coordinates_longitude: 115.86045719999993
            },
            {
                locality: 'Adelaide',
                capital: true,
                coordinates_latitude: -34.92849890000001,
                coordinates_longitude: 138.60074559999998
            },
            {
                locality: 'Gold Coast',
                capital: false,
                coordinates_latitude: -28.016667,
                coordinates_longitude: 153.39999999999998
            },
            {
                locality: 'Newcastle',
                capital: false,
                coordinates_latitude: -32.9282712,
                coordinates_longitude: 151.78168019999998
            },
            {
                locality: 'Canberra',
                capital: true,
                coordinates_latitude: -35.3075,
                coordinates_longitude: 149.124417
            },
            {
                locality: 'Wollongong',
                capital: false,
                coordinates_latitude: -34.4278121,
                coordinates_longitude: 150.89306069999998
            },
            {
                locality: 'Geelong',
                capital: false,
                coordinates_latitude: -38.1499181,
                coordinates_longitude: 144.36171860000002
            },
            {
                locality: 'Hobart',
                capital: true,
                coordinates_latitude: -42.8821377,
                coordinates_longitude: 147.3271949
            },
            {
                locality: 'Darwin',
                capital: true,
                coordinates_latitude: -12.4634403,
                coordinates_longitude: 130.84564180000007
            }
        ];
    }

    static getCategoryDisplayExtras(category_id) {
        const data = {
            6: {
                title: 'Radio Advertising',
                description:
                    'Radio advertising provides the opportunity to pinpoint your perfect audience and stir their emotions with high frequency. Radio is a proven medium for reaching your audience while they are engaged and in an uncluttered environment.',
                image: '/assets/images/categories/radio.jpg'
            },
            1: {
                title: 'TV Advertising',
                description:
                    'Combine the mass appeal of television with the power of vision and sound and it is clear that TV advertising is still the most effective way to reach a mass audience even with only a few ad spots.',
                image: '/assets/images/categories/tv.jpg'
            },
            49: {
                title: 'Cinema Advertising',
                description:
                    'There is no better way to reach a captive and engaged audience than with cinema advertising. The effectiveness of cinema advertising is undisputed as the measured recall rate of cinema ads is extremely high.',
                image: '/assets/images/categories/cinema.jpg'
            },
            59: {
                title: 'Sponsorship',
                description:
                    'Increasingly popular, sponsorship of all types is a great form of advertising for business of all sizes. The increased brand awareness that comes with association with causes, events, community and sports provides great ROI. ',
                image: '/assets/images/categories/sponsorship.jpg'
            },
            54: {
                title: 'Digital Advertising',
                description:
                    'Targeted digital advertising can be personalized and is powerful, cost effective, measurable, trackable, shareable and is available exactly where your audience is most of the time. Digital advertising formats are constantly evolving in order to meet market needs.',
                image: '/assets/images/categories/digital.jpg'
            },
            27: {
                title: 'Outdoor Advertising',
                description:
                    'Outdoor or OOH (out of home) advertising presents many opportunities for advertisers to reach their specific target audience. It’s the perfect way to spread your message at multiple touch points throughout a customer’s day. ',
                image: '/assets/images/categories/outdoor.jpg'
            },
            48: {
                title: 'Activation',
                description:
                    'Activation advertising shows consumers that promises made in the advertised message are true. This is done by the use of a product or experience that activates the value of your brand to the consumer.   ',
                image: '/assets/images/categories/retail.jpg'
            },
            43: {
                title: 'Experiential',
                description:
                    'Experiential advertising uses activities in which consumers can participate in order to engage with a branded experience. Sometimes referred to as ‘live’ advertising, it is extremely effective in creating a memorable impact on the consumer.',
                image: '/assets/images/categories/retail.jpg'
            },
            38: {
                title: 'Retail Advertising',
                description:
                    'Retail advertising can deliver on multiple marketing objectives at the same time. Target specific demographics with strategically positioned messages. Numerous options include panels, banners, window decals, digital screens, floor space, amenities and furniture.',
                image: '/assets/images/categories/retail.jpg'
            },
            30: {
                title: 'Digital Billboards',
                description:
                    'Digital billboard advertising is exploding because it’s flexible and it works. Deliver your dynamic content with pinpoint timing and reduced production costs. Vibrant crystal clear images help to present your brand in the best possible way.',
                image: '/assets/images/categories/outdoor.jpg'
            },
            29: {
                title: 'Small Format Outdoor',
                description:
                    'Well positioned small format advertising provides a great return on investment as it delivers your message to a finely targeted demographic. Typically portrait and increasingly available in digital, small format advertising is attention grabbing.',
                image: '/assets/images/categories/smallformat.jpg'
            },
            28: {
                title: 'Large Format Outdoor',
                description:
                    'Large format advertising immediately grabs the attention of your customers due to their sheer size and your powerful message. Digital or printed, from large to super-large and strategically positioned on roadside billboards, gantry signs and buildings, there is no denying the impact they deliver.',
                image: '/assets/images/categories/outdoor.jpg'
            },
            10: {
                title: 'Print Advertising',
                description:
                    'Tried, true, and extremely targeted, print advertising continues to provide great value for advertisers. With amazing targeted reach coupled with shelf life and the pass-on factor, advertising in flyers, magazines, and newspapers has many benefits.',
                image: '/assets/images/categories/print.jpg'
            },
            39: {
                title: 'Convenience Advertising',
                description:
                    'Convenience advertising is undeniably effective. Get your message in front of your targets in a closed and personal environment. Dynamic or static in retail bathrooms, parent rooms, schools, universities and pubs, convenience advertising provides high recall rates.',
                image: '/assets/images/categories/convenience.jpg'
            },
            35: {
                title: 'Rock Posters',
                description:
                    'Rock poster advertising is especially effective for the arts and entertainment industries but can be used for many verticals. Get your event or brand in front of your consumers while they commute. Rock poster advertising can be an integral part of your campaign.',
                image: '/assets/images/categories/rockposter.jpg'
            },
            44: {
                title: 'Ambient Advertising',
                description:
                    'Ambient advertising involves the integration of advertisements in everyday environments. Ambient ads can be used to make someone think differently about your product or brand. When looking for differentiation, ambient advertising is a powerful tool.',
                image: '/assets/images/categories/outdoor.jpg'
            },
            41: {
                title: 'Hoardings',
                description:
                    'Hoarder advertising is multi-purpose and extremely effective. With a multitude of sizes available, hoardings stand out to pedestrians and people travelling in vehicles at key times in their day.',
                image: '/assets/images/categories/outdoor.jpg'
            },
            33: {
                title: 'Bus Advertising',
                description:
                    'Bus Advertising is extremely effective because it is big, brash, and unavoidable. Build your brand, launch that product or promote that activation.',
                image: '/assets/images/categories/bus.jpg'
            },
            36: {
                title: 'Airport Advertising',
                description:
                    'Airport advertising portrays your brand as one of quality to business and leisure travellers year round. With high dwell time and options including, billboards (all sizes), light boxes, and airport interiors, your message follows your audience through their airport journey.',
                image: '/assets/images/categories/airport.jpg'
            },
            40: {
                title: 'Rail Advertising',
                description:
                    'Rail advertising delivers high engagement to a receptive and captive commuter audience. Numerous advertising options include train exteriors and interiors, wraps, shelters, and furniture.',
                image: '/assets/images/categories/rail.jpg'
            },
            42: {
                title: 'Scooter Advertising',
                description:
                    'Scooter advertising gives you access to areas in which larger format advertising is not possible. Versatile, innovative and great for city streets, crowded shopping strips and built-up areas, scooter advertising immediately grabs the attention of your audience.',
                image: '/assets/images/categories/scooter.jpg'
            },
            32: {
                title: 'Taxi Advertising',
                description:
                    'Highlight your brand and product with Taxi advertising. Target your message directly in the areas where people work, shop, and play. With this wide coverage, Taxi advertising ensures you reach your market.',
                image: '/assets/images/categories/taxi.jpg'
            },
            45: {
                title: 'Trailers',
                description:
                    'Trailer advertising lets you stand out with high impact, local and targeted advertising that works. Trailers are mobile billboards that are cost effective, create a long lasting impression, and can motivate your audience with call to action. ',
                image: '/assets/images/categories/trailer.jpg'
            },
            46: {
                title: 'Trucks',
                description:
                    'Truck advertising brings the power of mobile outdoor advertising within reach. Effective because it delivers your message to your customers far and wide at street level.',
                image: '/assets/images/categories/truck.jpg'
            },
            34: {
                title: 'Trams',
                description:
                    'A simple yet powerful way to reach local audiences, tram advertising is effective because of the time people spend waiting for and the time they spend on a tram. Tram route demographics allows for optimal targeted messaging.',
                image: '/assets/images/categories/tram.jpg'
            },
            31: {
                title: 'Street Furniture',
                description:
                    'Street furniture advertising is positioned at close proximity to pedestrians and public amenities and is impactful at eye level to both pedestrians and those traveling in vehicles. Numerous options include transit shelters, benches, information pillars, newsstands, and kiosks.',
                image: '/assets/images/categories/streetfurniture.jpg'
            },
            47: {
                title: 'Stadium Advertising',
                description:
                    'Stadium and perimeter advertising is acknowledged as ultra effective and impactful. It is a proven way of promoting your company to a captive audience whilst guaranteeing wide brand exposure with extensive TV coverage on match days.',
                image: '/assets/images/categories/stadium.jpg'
            },
            37: {
                title: 'Airline Advertising',
                description:
                    'Airline advertising takes advantage of the completely captive and receptive situation your audience is in. Powerful and innovative options include, tray advertising, seat back inserts, overhead bins as well as the tried and true in-flight magazines and videos.',
                image: '/assets/images/categories/airline.jpg'
            }
        };

        return data[category_id.toString()];
    }
}
