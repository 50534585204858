export class UserModel {
    id: number;
    displayName: string;
    email: string;
    token: string;
    firstName: string;
    lastName: string;
    company_type: string;
    company_type_binary: string;
    company_name: string;
    company_id: number;
    isAdmin: boolean;
    isImpersonating: boolean;

    constructor(id: number, firstName: string, lastName: string, displayName: string, email: string, isAdmin: boolean) {
        this.id = id;
        this.displayName = displayName;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.isAdmin = isAdmin;
    }
}
