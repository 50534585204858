import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { SessionService } from '../services/session.service';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class CategoriesApiService extends BaseApiService {
    constructor(protected sessionService: SessionService, private httpClient: HttpClient) {
        super();
    }

    getGenders() {
        const resourceName = 'gender';

        const url = this.buildUrl(resourceName);
        const headers = this.initialiseHeaders();

        // Gets all products and modifies the responses for UI
        return this.httpClient
            .get(url, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    getMediaCategories() {
        const resourceName = 'media-category';

        const url = this.buildUrl(resourceName);
        const headers = this.initialiseHeaders();

        // Gets all products and modifies the responses for UI
        return this.httpClient
            .get(url, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    getAgeCategories() {
        const resourceName = 'age-category';

        const url = this.buildUrl(resourceName);
        const headers = this.initialiseHeaders();

        // Gets all products and modifies the responses for UI
        return this.httpClient
            .get(url, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }
}
