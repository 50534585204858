import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { LocalService } from './local.service';

@Injectable({ providedIn: 'root' })
export class ListsLocalService extends LocalService {
    constructor(public storage: Storage, public httpClient: HttpClient) {
        super(storage, httpClient);
        this.key = 'currentlistid';
    }

    get() {
        return this.getValue().then(listId => {
            return listId;
        });
    }

    set(listId: any) {
        return this.setValue(listId).then(retVal => {
            return retVal;
        });
    }

    remove() {
        return this.removeKey();
    }
}
