import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { SessionService } from '../services/session.service';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class CartApiService extends BaseApiService {
    resourceRoot = 'user';

    constructor(protected sessionService: SessionService, private httpClient: HttpClient) {
        super();
    }

    getCartForUser(userId: number, token: string) {
        const url = this.buildUrl(this.resourceRoot + '/' + userId + '/cart');
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        // Gets all products and modifies the responses for UI
        return this.httpClient
            .get(url, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    getListingInfoForCart(itemListingScheduleIds: any[], token: string = null) {
        const url = this.buildUrl('item-listing-schedule/cart-info');
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        // Prepare the JSON body
        const body = {
            ids: itemListingScheduleIds
        };

        // Gets all products and modifies the responses for UI
        return this.httpClient
            .post(url, body, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    syncItemListingsForUser(userId: number, itemListingScheduleIds: any[], token: string) {
        const url = this.buildUrl(this.resourceRoot + '/' + userId + '/cart');
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        // Prepare the JSON body
        const body = {
            ids: itemListingScheduleIds
        };

        // Puts/Syncs the itemListing Ids to the Cart
        return this.httpClient
            .put(url, body, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    addItemListingsForUser(userId: number, itemListingScheduleIds: any[], token: string) {
        const url = this.buildUrl(this.resourceRoot + '/' + userId + '/cart');
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        // Prepare the JSON body
        const body = {
            ids: itemListingScheduleIds
        };

        return this.httpClient
            .post(url, body, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }

    removeItemListingForUser(userId: number, itemListingScheduleIds: number, token: string) {
        const url = this.buildUrl(this.resourceRoot + '/' + userId + '/cart/' + itemListingScheduleIds);
        const headers = this.addTokenToHeaders(this.initialiseHeaders(), token);

        return this.httpClient
            .delete(url, { headers: headers })
            .pipe(catchError((error: any) => this.handleRequestError(error, this.sessionService)));
    }
}
