import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

import { Cart } from '../../models/cart.model';
import { LocalService } from './local.service';

@Injectable({ providedIn: 'root' })
export class CartLocalService extends LocalService {
    constructor(public storage: Storage, public httpClient: HttpClient) {
        super(storage, httpClient);
        this.key = 'cart';
    }

    get(userId: string) {
        return this.getValue().then(data => {
            data = this.prepareData(data);
            data[userId] = this.initialiseCart(userId, data);

            return data;
        });
    }

    add(userId: string, itemListingScheduleIds: any[]) {
        return this.getValue().then(data => {
            data = this.prepareData(data);
            const cart = this.initialiseCart(userId, data);

            for (const id of itemListingScheduleIds) {
                // Check if product already exists
                if (!cart.itemListingSchedules.data.includes(id)) {
                    cart.itemListingSchedules.data.push(id);
                    cart.updated_at_time = String(Date.now());
                }

                data[userId] = cart;
            }

            // Update the data on local storage
            this.setValue(data);

            return data;
        });
    }

    remove(userId: string, itemListingScheduleId: any) {
        return this.getValue().then(data => {
            data = this.prepareData(data);
            const cart = this.initialiseCart(userId, data);

            const index = cart.itemListingSchedules.data.findIndex(cartItem => cartItem === itemListingScheduleId);
            cart.itemListingSchedules.data.splice(index, 1);

            data[userId] = cart;
            this.setValue(data);

            return data;
        });
    }

    sync(userId: string, itemListingScheduleIds: any[]) {
        // Simply overwrite the itemListings in
        // local storage with the provided data

        return this.getValue().then(data => {
            data = this.prepareData(data);
            const cart = this.initialiseCart(userId, data);
            cart.itemListingSchedules.data = itemListingScheduleIds;
            cart.updated_at_time = String(Date.now());
            data[userId] = cart;
            this.setValue(data);

            return data;
        });
    }

    emptyCart(userId: string) {
        return this.sync(userId, []);
    }

    private prepareData(data) {
        // If no data - create data container
        if (!data) {
            data = {};
        }
        return data;
    }

    private initialiseCart(userId, data): Cart {
        let cart: Cart;

        // If no current data, create placeholder wishlist
        if (!(userId in data) || !data[userId].itemListingSchedules) {
            cart = new Cart([], '');
        } else {
            cart = data[userId];
        }

        return cart;
    }
}
