import { Injectable } from '@angular/core';

import * as $ from 'jquery';
import { OffCanvas } from 'foundation-sites/js/foundation';
import { DropdownMenu } from 'foundation-sites/js/foundation';

@Injectable({ providedIn: 'root' })
export class MenuService {
    menu_main: OffCanvas;
    menu_account: OffCanvas;
    menu_lists: OffCanvas;
    menu_loginwall: OffCanvas;
    browse_menu: DropdownMenu;

    constructor() {}

    public createBrowseDropDown() {
        const dropdown = $('ion-app .ion-page:not(.ion-page-hidden) ul.menu[data-dropdown-menu]');
        this.browse_menu = new Foundation.DropdownMenu(dropdown, {});
        return this.browse_menu;
    }

    public createMainMenu() {
        // Init the main slideout menu
        const menu = $('ion-app .ion-page:not(.ion-page-hidden) #menu-main');
        this.menu_main = new Foundation.OffCanvas(menu);
        this.addMenuClosingEvents(menu);
        return this.menu_main;
    }

    public createAccountMenu() {
        // Init the main slideout menu
        const menu = $('ion-app .ion-page:not(.ion-page-hidden) #menu-account');
        this.menu_account = new Foundation.OffCanvas(menu);
        this.addMenuClosingEvents(menu);
        return this.menu_account;
    }

    public createListsMenu() {
        // Init the main slideout menu
        const menu = $('ion-app .ion-page:not(.ion-page-hidden) #menu-lists');
        this.menu_lists = new Foundation.OffCanvas(menu);
        this.addMenuClosingEvents(menu);
        return this.menu_lists;
    }

    public createLoginWallMenu() {
        // Init the main slideout menu
        const menu = $('ion-app .ion-page:not(.ion-page-hidden) #menu-loginwall');
        this.menu_loginwall = new Foundation.OffCanvas(menu);
        this.addMenuClosingEvents(menu);
        return this.menu_loginwall;
    }

    protected addMenuClosingEvents(menu) {
        // Hack because ionic stacks the pages fo history, creating duplicate elements and events
        menu.on('openedEnd.zf.offCanvas', event => {
            menu.removeClass('is-closed');
        });
        menu.on('close.zf.offCanvas', event => {
            menu.addClass('is-closed');
        });
    }
}
