import { Injectable, OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';
import { timer } from 'rxjs';

import { ActivateAccountCredentials } from '../../models/activate-account.model';
import { AuthCredentials } from '../../models/auth-credentials.model';
import { AuthApiService } from '../api/auth.service';
import { ImpersonateApiService } from '../api/impersonate.service';
import { SessionService } from './session.service';
import { UserModel } from 'src/app/models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
    protected refreshSubscription: any;

    constructor(
        private authApiService: AuthApiService,
        private impersonateApiService: ImpersonateApiService,
        protected sessionService: SessionService
    ) {}

    ngOnDestroy() {
        this.unscheduleRefresh();
    }

    register(credentials) {
        return this.authApiService.register(credentials);
    }

    login(credentials: AuthCredentials) {
        return this.authApiService.login(credentials).pipe(
            map(
                response => {
                    // Extract and prepare the important information from the response
                    this.scheduleRefresh(response.meta.token.expires_in);

                    return {
                        user: response.data,
                        company: response.data.company,
                        token: response.meta.token.access_token
                    };
                },
                error => {
                    return error;
                }
            )
        );
    }

    logout() {
        // Get instance of the current user
        this.unscheduleRefresh();

        return this.sessionService
            .getCurrentUser()
            .then(user => {
                // Get the token from the user
                user = user as UserModel;
                const token = user.token;

                // Remove the user from local memory
                return this.sessionService.removeUser().then(() => {
                    // Logout on the server
                    return this.authApiService.logout(token).subscribe(
                        response => {
                            // noop now?
                        },
                        error => {
                            console.error('Error logging out', error);
                        }
                    );
                });
            })
            .catch(reason => {
                console.warn(reason);
            });
    }

    public impersonate(userId: string, token: string) {
        this.unscheduleRefresh();
        return this.impersonateApiService.take(token, userId).pipe(
            map(
                response => {
                    return this.handleImpersonationChange(response);
                },
                error => {
                    return error;
                }
            )
        );
    }

    public leaveImpersonation(token: string) {
        this.unscheduleRefresh();
        return this.impersonateApiService.leave(token).pipe(
            map(
                response => {
                    return this.handleImpersonationChange(response);
                },
                error => {
                    return error;
                }
            )
        );
    }

    private handleImpersonationChange(apiResponse: any) {
        // We don't get token meta from the impersonate call, so we don't have a genuine
        // expiry. We could decode the token and inspect the 'exp' claim, but for now
        // we just schedule an early refresh which will then get us back on track
        this.scheduleRefresh(10);
        return apiResponse;
    }

    public scheduleRefresh(expiresIn: number) {
        this.unscheduleRefresh();

        // Re-auth halfway through the token expiry time
        const expiresAtTimer = timer((expiresIn * 1000) / 2);

        // Once the delay time from above is reached, get a new JWT and schedule additional refreshes
        this.refreshSubscription = expiresAtTimer.subscribe(() => {
            this.sessionService.getCurrentUser().then(user => {
                user = user as UserModel;
                this.refreshToken(user.token)
                    .toPromise()
                    .then(
                        response => {
                            user = user as UserModel;
                            if (response.meta.token) {
                                user.token = response.meta.token.access_token;

                                this.sessionService.setUser(user);
                                this.scheduleRefresh(response.meta.token.expires_in);
                                return true;
                            } else {
                                this.sessionService.expireSession();
                                console.warn('Unable to refresh token on server', response);
                                return false;
                            }
                        },
                        error => {
                            this.sessionService.expireSession();
                            if (error.code !== 'GEN-UNAUTHORIZED') {
                                console.error('Error when refreshing token on server', error);
                            }
                            return false;
                        }
                    );
            });
        });
    }

    public unscheduleRefresh() {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }

    public hasScheduledRefresh() {
        return !!this.refreshSubscription;
    }

    activate(credentials: ActivateAccountCredentials) {
        return this.authApiService.activate(credentials).pipe(
            map(
                response => {
                    // Extract and prepare the important information from the response
                    return {
                        user: response.data,
                        company: response.data.company,
                        token: response.meta.token.access_token
                    };
                },
                error => {
                    return error;
                }
            )
        );
    }

    refreshToken(token: string) {
        return this.authApiService.refreshToken(token);
    }

    updatePassword(data: any, token: string) {
        return this.authApiService.updatePassword(token, data);
    }
}
