import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { UserModel } from '../../models/user.model';
import { Wishlist } from '../../models/wishlist.model';
import { WishlistApiService } from '../api/wishlist.service';
import { SessionService } from './session.service';
import { UserFeedbackService } from '../../providers/helpers/user-feedback.service';
import uniqBy from 'lodash-es/uniqBy';
import { CategoryHelper } from 'src/app/providers/helpers/category-helper';
import { ListsLocalService } from '../../providers/local/lists.service';
import { MenuService } from './menu.service';

export enum WishlistItemType {
    Item = 'item',
    Listing = 'listing',
    Schedule = 'schedule'
}

export enum WishlistShareType {
    Public = 'public',
    Private = 'private'
}

@Injectable({ providedIn: 'root' })
export class WishlistService {
    wishlist$ = new BehaviorSubject<Wishlist | null>(null);

    wishlists: Wishlist[] = [];

    constructor(
        private wishlistApiService: WishlistApiService,
        private sessionService: SessionService,
        private userFeedbackService: UserFeedbackService,
        private listLocalService: ListsLocalService,
        protected menuService: MenuService
    ) {
        this.getUserWishlists();
    }

    destroy() {}

    public watchWishlist(): Observable<Wishlist | null> {
        return this.wishlist$;
    }

    public peekWishlist(): Wishlist | null {
        return this.wishlist$.value;
    }

    public pokeWishlist(wishlist: Wishlist | null): void {
        this.wishlist$.next(wishlist);
        if (wishlist && wishlist.id) {
            this.listLocalService.set(wishlist.id);
        }
    }

    protected getUserWishlists() {
        this.sessionService
            .watchUser()
            .pipe(untilDestroyed(this, 'destroy'))
            .subscribe(user => {
                if (typeof user === 'object' && user !== null) {
                    this.wishlistApiService
                        .getUserWishlists(user.id, user.token)
                        .pipe(untilDestroyed(this, 'destroy'))
                        .subscribe(response => {
                            const lists = response['data'];
                            if (lists.length === 0) {
                                this.createNew({ title: 'My Campaign' });
                            } else {
                                // Put all loaded lists into out local array
                                this.wishlists = [];
                                for (const item of response['data']) {
                                    this.wishlists.push(this.createListFromAPIResponse(item));
                                }
                                if (this.wishlists.length > 0) {
                                    // Check last stored list id
                                    this.listLocalService.get().then(lastid => {
                                        if (lastid) {
                                            const lastList = this.wishlists.filter(item => item.id === lastid);
                                            if (lastList.length > 0) {
                                                this.pokeWishlist(lastList[0]);
                                            } else {
                                                this.listLocalService.remove();
                                            }
                                        } else {
                                            // Set the current list to the first result
                                            const firstWishlist = this.wishlists.length > 0 ? this.wishlists[0] : null;
                                            this.pokeWishlist(firstWishlist);
                                        }
                                    });
                                }
                            }
                        });
                } else {
                    const wishlist = new Wishlist();
                    this.pokeWishlist(wishlist);
                }
            });
    }

    private createListFromAPIResponse(data) {
        const wishlist = new Wishlist(
            data.id,
            data.title,
            data.items,
            data.item_listings,
            data.item_listing_schedules,
            data.item_ids,
            data.item_listing_ids,
            data.item_listing_schedule_ids,
            data.slug,
            data.share_type,
            data.start_date,
            data.end_date,
            data.updated_at,
            data.is_active,
            data.user
        );

        // Put items into single parented heirarchy
        // Deduplicate item arrays

        let unique_items = [];
        let unique_listings = [];
        let unique_schedules = [];

        if (data.items.length > 0) {
            unique_items = uniqBy(data.items, 'id');
        }
        if (data.item_listings.length > 0) {
            unique_listings = uniqBy(data.item_listings, 'id');
        }
        if (data.item_listing_schedules.length > 0) {
            unique_schedules = uniqBy(data.item_listing_schedules, 'id');
        }

        // get default image
        for (const item of unique_items) {
            if (!item.image && item.media_categories.length > 0) {
                item.default_image = null;

                // Get the best outdoor image
                for (const category of item.media_categories) {
                    if (category.parent_id === 27) {
                        item.default_image = CategoryHelper.getCategoryDisplayExtras(category.id).image;
                        break;
                    }
                }

                // Or use the first category
                if (!item.default_image) {
                    item.default_image = CategoryHelper.getCategoryDisplayExtras(item.media_categories[0].id).image;
                }
            }
        }

        for (const listing of unique_listings) {
            listing.item_listing_schedules = [];
            // Put schedules under listings
            for (const schedule of unique_schedules) {
                if (schedule.item_listing_id === listing.id) {
                    listing.item_listing_schedules.push(schedule);
                }
            }

            // Put listings under items
            for (const item of unique_items) {
                if (!item.item_listings) item.item_listings = [];
                if (listing.item_id === item.id) {
                    item.item_listings.push(listing);
                }
            }
        }

        // Put new structure under items;
        wishlist.items = unique_items;

        return wishlist;
    }

    getBigListBySlug(slug: string) {
        // Check if user is logged in.
        return this.sessionService
            .getCurrentUser()
            .then(user => {
                user = user as UserModel;
                return this.wishlistApiService
                    .getBigWishlistBySlug(slug, user.token)
                    .toPromise<any>()
                    .then(response => {
                        return this.createListFromAPIResponse(response.data);
                    });
            })
            .catch(reason => {
                return this.wishlistApiService
                    .getBigWishlistBySlug(slug, null)
                    .toPromise<any>()
                    .then(response => {
                        return this.createListFromAPIResponse(response.data);
                    });
            });
    }

    loadListById(listId: number) {
        return this.sessionService
            .getCurrentUser()
            .then(user => {
                user = user as UserModel;
                return this.wishlistApiService
                    .getWishlistByIdForUser(user.id, listId, user.token)
                    .toPromise<any>()
                    .then(response => {
                        const wishlist = this.createListFromAPIResponse(response['data']);
                        this.pokeWishlist(wishlist);
                    });
            })
            .catch(error => {
                console.warn('Error loading list.', error);
            });
    }

    createNew(data: any) {
        return this.sessionService
            .getCurrentUser()
            .then(response => {
                const user = response as UserModel;
                return this.wishlistApiService
                    .addWishlistForUser(user.id, data, user.token)
                    .toPromise()
                    .then(newlist => {
                        const wishlist = newlist['data'];
                        this.pokeWishlist(wishlist);
                        this.userFeedbackService.presentToastSuccess('New list created.', false, true);
                        return wishlist;
                    });
            })
            .catch(error => {
                console.warn('Error creating new list.', error);
            });
    }

    update(data: any) {
        const currentList = this.peekWishlist();
        if (currentList && currentList.id) {
            this.sessionService
                .getCurrentUser()
                .then(user => {
                    user = user as UserModel;
                    return this.wishlistApiService
                        .updateWishlistForUser(user.id, currentList.id, data, user.token)
                        .toPromise()
                        .then(response => {
                            const wishlist = this.createListFromAPIResponse(response['data']);
                            this.pokeWishlist(wishlist);
                            //return wishlist;
                        });
                })
                .catch(error => {
                    console.warn('Error updating list.', error);
                });
        } else {
            console.warn('No current list found.');
        }
    }

    // Not sure if we're deleting of just disabling
    delete() {
        const currentList = this.peekWishlist();
        if (currentList && currentList.id) {
            this.sessionService
                .getCurrentUser()
                .then(user => {
                    user = user as UserModel;
                    return this.wishlistApiService
                        .deleteList(user.id, currentList.id, user.token)
                        .toPromise()
                        .then(response => {
                            // reload the users lists, or make a new one if none...
                            this.getUserWishlists();
                        });
                })
                .catch(error => {
                    console.warn('Error deleting list.', error);
                });
        } else {
            console.warn('No current list found.');
        }
    }

    share(share_type: string = 'private') {
        const currentList = this.peekWishlist();
        if (currentList && currentList.id) {
            return this.sessionService
                .getCurrentUser()
                .then(user => {
                    user = user as UserModel;
                    return this.wishlistApiService
                        .setListShareType(user.id, currentList.id, user.token, share_type)
                        .toPromise<any>()
                        .then(wishlist => {
                            wishlist = this.createListFromAPIResponse(wishlist['data']);
                            this.pokeWishlist(wishlist);
                            this.userFeedbackService.presentToastSuccess('List is now set to ' + share_type + '.', false, true);
                            return wishlist;
                        });
                })
                .catch(error => {
                    console.warn('Error updating list.', error);
                });
        } else {
            console.warn('No current list found.');
        }
    }

    add(itemId: number, type: string = 'item') {
        return new Promise<any | boolean>((resolve, reject) => {
            const currentList = this.peekWishlist();
            if (currentList && currentList.id) {
                return this.sessionService
                    .getCurrentUser()
                    .then(user => {
                        user = user as UserModel;
                        return this.wishlistApiService
                            .addItemForUser(user.id, currentList.id, itemId, type, user.token)
                            .toPromise()
                            .then(response => {
                                const wishlist = this.createListFromAPIResponse(response['data']);
                                this.pokeWishlist(wishlist);
                                this.userFeedbackService.presentToastSuccess(
                                    'Item added to list "' + currentList.title + '".',
                                    false,
                                    true
                                );
                                resolve(wishlist);
                            });
                    })
                    .catch(error => {
                        console.warn('Error adding item to list.', error);
                        reject(false);
                    });
            } else {
                console.warn('No current list found.');
                this.menuService.menu_loginwall.open();
            }
        });
    }

    /**
     * Removes an item from the current list.
     *
     * @param  int itemId item,listing or schedule ID
     * @param  string type 'item', 'listing', or 'schedule'.
     */
    remove(itemId: number, type: string) {
        return new Promise<any | boolean>((resolve, reject) => {
            const currentList = this.peekWishlist();
            if (currentList && currentList.id) {
                return this.sessionService
                    .getCurrentUser()
                    .then(user => {
                        user = user as UserModel;
                        return this.wishlistApiService
                            .removeItemForUser(user.id, currentList.id, itemId, type, user.token)
                            .toPromise()
                            .then(response => {
                                const wishlist = this.createListFromAPIResponse(response['data']);
                                this.pokeWishlist(wishlist);
                                resolve(wishlist);
                            });
                    })
                    .catch(error => {
                        console.warn('Error removing item from list', error);
                        reject(false);
                    });
            } else {
                console.warn('No current list found.');
            }
        });
    }

    protected createArrayOfItemListingIdsFromItemListings(itemListingSchedules: any[]) {
        // Prepare a list of Ids to sync
        const itemListingScheduleIds = [];
        for (const itemListingSchedule of itemListingSchedules) {
            itemListingScheduleIds.push(itemListingSchedule.id);
        }

        return itemListingScheduleIds;
    }

    getNumberOfItems() {
        const wishlist = this.peekWishlist();
        if (wishlist instanceof Wishlist) {
            return wishlist.items.length;
        } else {
            return 0;
        }
    }

    isItemIdInWatchlist(itemId: number) {
        const wishlist = this.peekWishlist();
        if (!wishlist) {
            return false;
        }
        return wishlist.itemIds.includes(itemId);
    }

    isItemListingIdInWatchlist(itemListingId: number) {
        const wishlist = this.peekWishlist();
        if (!wishlist) {
            return false;
        }
        return wishlist.itemListingIds.includes(itemListingId);
    }

    isScheduleIdInWatchlist(itemListingScheduleId: number) {
        const wishlist = this.peekWishlist();
        if (!wishlist) {
            return false;
        }
        return wishlist.itemListingScheduleIds.includes(itemListingScheduleId);
    }
}
