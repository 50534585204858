import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EmailValidators, UniversalValidators } from 'ngx-validators';
import { CreditCardValidator } from 'angular-cc-library';

@Injectable({ providedIn: 'root' })
// A service to ensure DRY protocol.
export class FormHelperService {
    constructor(private formBuilder: FormBuilder) {}

    getAuthModel() {
        return {
            email: ['', this.getEmailValidators()],
            password: ['', this.getPasswordValidators()]
        };
    }

    getFormGroupForSignupPage() {
        return this.formBuilder.group({
            auth: this.formBuilder.group(this.getAuthModel()),
            user: this.formBuilder.group(this.getUserModelForSignup()),
            company: this.formBuilder.group(this.getCompanyModelForSignup())
        });
    }

    getFormGroupForCampaignBuilder() {
        return this.formBuilder.group({
            budget: this.formBuilder.group(this.getSearchBudgetModel()),
            dates: this.formBuilder.group(this.getDateRangeModel()),
            mediasplit: this.formBuilder.group(this.getCampaignBuilderMediaSplitModel()),
            audience: this.formBuilder.group(this.getSearchAudienceModel())
        });
    }

    getHomepageFormGroup() {
        return this.formBuilder.group({
            dates: this.formBuilder.group(this.getDateRangeModel())
        });
    }

    getCreateWishlistFormGroup() {
        return this.formBuilder.group({
            title: ['', this.getRequiredStringValidators()]
        });
    }

    getEditWishlistFormGroup() {
        return this.formBuilder.group({
            title: ['', this.getRequiredStringValidators()],
            start_date: this.getRequiredStringValidators(),
            end_date: this.getRequiredStringValidators(),
            is_active: [true, this.getRequiredValidator()]
        });
    }

    getSearchFilterFormGroup() {
        return this.formBuilder.group({
            budget: this.formBuilder.group(this.getSearchBudgetModel()),
            dates: this.formBuilder.group(this.getDateRangeModel()),
            audience: this.formBuilder.group(this.getSearchAudienceModel()),
            categories: this.formBuilder.group(this.getSearchCategoryModel()),
            locations: this.formBuilder.group(this.getLocationsListModel())
        });
    }

    getManageInventoryItemFormGroup() {
        return this.formBuilder.group({
            description: this.formBuilder.group(this.getInventoryItemDescriptionModel()),
            // prettier-ignore
            streetview_url: ['', Validators.compose([Validators.pattern('^https://goo\\.gl/maps/.+')])],
            audience: this.formBuilder.group(this.getSearchAudienceModel()),
            video: this.formBuilder.group(this.getVideoModel()),
            creative_restrictions: this.formBuilder.group(this.getCreativeRestrictionsModel())
        });
    }

    getManageItemListingFormGroup() {
        return this.formBuilder.group({
            description: this.formBuilder.group(this.getListingDescriptionModel()),
            pricing: this.formBuilder.group(this.getInventoryItemPricingModel())
        });
    }

    getAddAvailabilityFormGroup() {
        return this.formBuilder.group({
            start_date: ['', this.getRequiredStringValidators()],
            run_length: [2, this.getRequiredValidator()],
            run_length_unit: ['week', this.getRequiredStringValidators()],
            quantity_remaining: [1, this.getRequiredValidator()],
            creative_due_days: [3, this.getRequiredValidator()],
            listing_lead_time: [12, this.getRequiredValidator()],
            repeat_frequency: [4, this.getRequiredValidator()],
            repeat_frequency_unit: ['week', this.getRequiredStringValidators()],
            repeat_end: [null],
            replace_existing: [false, this.getRequiredValidator()]
        });
    }

    getEditAvailabilityFormGroup() {
        return this.formBuilder.group({
            schedule_start_date: ['', this.getRequiredStringValidators()],
            schedule_end_date: ['', this.getRequiredStringValidators()],
            creative_due_date: ['', this.getRequiredStringValidators()],
            listing_start_date: ['', this.getRequiredStringValidators()],
            listing_end_date: ['', this.getRequiredStringValidators()],
            quantity_remaining: [1, this.getRequiredValidator()]
        });
    }

    getDuplicateInventoryItemListingFormGroup() {
        return this.formBuilder.group({
            title: ['', this.getRequiredStringValidators()],
            pricing: this.formBuilder.group({
                retail_price: ['', this.getRequiredStringValidators()],
                sale_price: ['', this.getRequiredStringValidators()]
            }),
            include_schedules: [true, this.getRequiredValidator()]
        });
    }

    getRegisterFormGroup() {
        return this.formBuilder.group({
            name: ['', this.getRequiredStringValidators()],
            phone: ['', this.getPhoneValidator()],
            email: ['', this.getEmailValidators()],
            company: ['', this.getRequiredStringValidators()],
            client_type: ['', this.getRequiredStringValidators()]
        });
    }

    getProductEnquiryFormGroup() {
        return this.formBuilder.group({
            item_id: null,
            categories: null,
            url: null,
            name: ['', this.getRequiredStringValidators()],
            email: ['', this.getEmailValidators()],
            company: ['', this.getRequiredStringValidators()],
            phone: ['', this.getPhoneValidator()],
            start_date: null,
            end_date: null,
            message: ['', this.getRequiredStringValidators()]
        });
    }

    getContactFormGroup() {
        return this.formBuilder.group({
            name: ['', this.getRequiredStringValidators()],
            contact: ['', this.getRequiredStringValidators()],
            company: [''],
            message: ['', this.getRequiredStringValidators()]
        });
    }

    getFormGroupForLoginPage() {
        return this.formBuilder.group({
            auth: this.formBuilder.group(this.getAuthModel())
        });
    }

    getFormGroupForForgotPassword() {
        return this.formBuilder.group({
            email: ['', this.getEmailValidators()]
        });
    }

    getFormGroupForRestPassword() {
        return this.formBuilder.group({
            email: ['', this.getEmailValidators()],
            password: ['', this.getPasswordValidators()],
            password_confirmation: ['', this.getPasswordValidators()],
            token: ['', this.getRequiredValidator()]
        });
    }

    getFormGroupForChangePassword() {
        return this.formBuilder.group({
            password: ['', this.getPasswordValidators()],
            password_confirmation: ['', this.getPasswordValidators()]
        });
    }

    getFormGroupForUserInfo() {
        return this.formBuilder.group({
            user: this.formBuilder.group(this.getUserModel())
        });
    }

    getFormGroupForCompanyDetails() {
        return this.formBuilder.group({
            company: this.formBuilder.group(this.getCompanyModel())
        });
    }

    getFormGroupForBankingInfo() {
        return this.formBuilder.group({
            bank: this.formBuilder.group(this.getBankAccountModel())
        });
    }

    getFormGroupForCardAccount() {
        return this.formBuilder.group({
            card: this.formBuilder.group(this.getCardAccountModel())
        });
    }

    getUserModelForSignup() {
        return {
            first_name: ['', this.getNameValidators()],
            last_name: ['', this.getNameValidators()],
            dob: ['', this.getRequiredValidator()],
            newsletter: [true, this.getRequiredValidator()],
            terms_agreed: [false, this.getRequiredValidator()],
            // notification address is not required, so we don't use this.getEmailValidators()
            notification_address: ['', EmailValidators.normal]
        };
    }

    getUserModel() {
        return {
            email: ['', this.getEmailValidators()],
            notification_address: ['', EmailValidators.normal],
            use_login_email: true,
            first_name: ['', this.getNameValidators()],
            last_name: ['', this.getNameValidators()],
            newsletter: ['', this.getRequiredValidator()],
            mail_buyer_updates: null,
            mail_owner_updates: null
        };
    }

    getCompanyModel() {
        return {
            name: ['', this.getRequiredStringValidators()],
            legal_name: ['', this.getRequiredStringValidators()],
            tax_number: ['', this.getABNValidator()],
            address_country: ['', this.getRequiredStringValidators()],
            address_city: ['', this.getRequiredStringValidators()],
            address_line_one: ['', this.getRequiredStringValidators()],
            address_postcode: ['', this.getRequiredStringValidators()],
            address_state: ['', this.getRequiredStringValidators()],
            phone: ['', this.getPhoneValidator()],
            terms: null,
            description: null
        };
    }

    getCompanyModelForSignup() {
        return {
            name: ['', this.getRequiredStringValidators()],
            type: ['', this.getRequiredStringValidators()],
            address_country: ['', this.getRequiredStringValidators()],
            address_city: ['', this.getRequiredStringValidators()],
            address_line_one: ['', this.getRequiredStringValidators()],
            address_postcode: ['', this.getPostcodeValidator()],
            address_state: ['', this.getRequiredStringValidators()],
            phone: ['', this.getPhoneValidator()],
            structure: ['', this.getRequiredValidator()],
            government_number: ['', this.getRequiredStringValidators()]
        };
    }

    getSearchBudgetModel() {
        return {
            min: ['', this.getBudgetValidator()],
            max: ['', this.getBudgetValidator()]
        };
    }

    getDateRangeModel() {
        return {
            startDate: [''],
            endDate: [''],
            range: ['']
        };
    }

    getListingDatesModel() {
        return {
            listing_end_date: ['', this.getRequiredStringValidators()],
            creative_due_date: ['', this.getRequiredStringValidators()]
        };
    }

    getListingScheduleDatesModel() {
        return {
            schedule_start_date: ['', this.getRequiredStringValidators()],
            schedule_end_date: ['', this.getRequiredStringValidators()]
        };
    }

    getCampaignBuilderMediaSplitModel() {
        return {
            television: ['0', this.getPercentageValidator()],
            radio: ['0', this.getPercentageValidator()],
            print: ['0', this.getPercentageValidator()],
            outdoor: ['0', this.getPercentageValidator()],
            cinema: ['0', this.getPercentageValidator()],
            digital: ['0', this.getPercentageValidator()],
            sponsorship: ['0', this.getPercentageValidator()]
        };
    }

    getInventoryItemDescriptionModel() {
        return {
            title: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
            subtitle: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
            description: [''],
            quantity_remaining: [1],
            specification: ['']
        };
    }

    getListingDescriptionModel() {
        return {
            title: ['', Validators.compose([Validators.required, Validators.minLength(3)])]
        };
    }

    getSearchCategoryModel() {
        return {
            types: ['']
        };
    }

    getVideoModel() {
        return {
            embedded_video_url: ['']
        };
    }

    getCreativeRestrictionsModel() {
        return {
            political_allowed: [true],
            gambling_allowed: [true],
            alcohol_allowed: [true],
            competing_business_allowed: [true],
            community_action_allowed: [true]
        };
    }

    getCategoriesModel() {
        return {
            media_categories: [''],
            age_categories: [''],
            genders: ['']
        };
    }

    getLocationsListModel() {
        return {
            geolocations: ['']
        };
    }

    getSearchAudienceModel() {
        return {
            audience_metric_value: [''],
            audience_metric_duration: ['monthly'],
            audience_metric_label: ['']
        };
    }

    getInventoryItemPricingModel() {
        return {
            retail_price: ['', this.getRequiredStringValidators()],
            sale_price: ['', this.getRequiredStringValidators()],
            discount_percentage: ['', this.getRequiredStringValidators()]
        };
    }

    // getBankAccountModel() {
    //
    //   return {
    //     name: ['', this.getRequiredStringValidators()],
    //     account_bsb: ['', this.getBankBSBValidator()],
    //     account_name: ['', this.getRequiredStringValidators()],
    //     account_number: ['', this.getBankAccountNumberValidator()],
    //     account_type: ['', this.getRequiredStringValidators()],
    //     account_holder_type: ['', this.getRequiredStringValidators()]
    //   };
    // }

    getBankAccountModel() {
        return {
            bank_name: ['', this.getRequiredStringValidators()],
            account_name: ['', this.getRequiredStringValidators()],
            routing_number: ['', this.getBankBSBValidator()],
            account_number: ['', this.getBankAccountNumberValidator()],
            account_type: ['savings', this.getRequiredStringValidators()],
            holder_type: ['business', this.getRequiredStringValidators()]
        };
    }

    getCardAccountModel() {
        return {
            full_name: ['', this.getRequiredStringValidators()],
            number: ['', this.getCreditCardNumberValidators()],
            expiry: ['', this.getCreditCardExpiryValidators()], // mm / yyyy
            cvv: ['', this.getCreditCardCVVValidator()] // xxx
        };
    }

    getRequiredValidator() {
        return Validators.required;
    }

    getNameValidators() {
        // Name is required, with minimum length of 2 characters, maximum of 12
        return Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(24)]);
    }

    getRequiredStringValidators() {
        // Is required, with minimum length of 2 characters
        return Validators.compose([Validators.required]);
    }

    getCreditCardNumberValidators() {
        return Validators.compose([Validators.required, CreditCardValidator.validateCCNumber]);
    }

    getCreditCardExpiryValidators() {
        return Validators.compose([Validators.required, CreditCardValidator.validateExpDate]);
    }

    getCreditCardCVVValidator() {
        return Validators.compose([
            Validators.required,
            UniversalValidators.isNumber,
            Validators.minLength(3),
            Validators.maxLength(4),
            UniversalValidators.noWhitespace
        ]);
    }

    getEmailValidators() {
        // Email is required with normal email patterns
        return Validators.compose([Validators.required, EmailValidators.normal]);
    }

    getPhoneValidator() {
        return Validators.compose([Validators.required, Validators.pattern('\\+?[0-9 ]+'), Validators.minLength(8)]);
    }

    getPasswordValidators() {
        return Validators.compose([Validators.required, Validators.minLength(8)]);
    }

    getBudgetValidator() {
        return Validators.compose([Validators.minLength(2), Validators.maxLength(11), UniversalValidators.noWhitespace]);
    }

    getPercentageValidator() {
        return Validators.compose([
            UniversalValidators.isNumber,
            Validators.minLength(1),
            Validators.maxLength(3),
            UniversalValidators.noWhitespace
        ]);
    }

    getCampaignDateValidator() {
        return Validators.compose([UniversalValidators.noWhitespace]);
    }

    getABNValidator() {
        // Allow for some spaces in the ABN, as they are normally formatted as 12 345 678 901
        return Validators.compose([Validators.minLength(11), Validators.maxLength(14)]);
    }

    getPostcodeValidator() {
        return Validators.compose([
            Validators.required,
            UniversalValidators.isNumber,
            Validators.minLength(4),
            Validators.maxLength(4),
            UniversalValidators.noWhitespace
        ]);
    }

    getBankAccountNumberValidator() {
        return Validators.compose([
            Validators.required,
            UniversalValidators.isNumber,
            Validators.minLength(6),
            Validators.maxLength(10),
            UniversalValidators.noWhitespace
        ]);
    }

    getBankBSBValidator() {
        // XXY-ZZZ
        // The First two digits (XX) specify the parent financial institution.
        // Third digit (Y) specifies the state where the branch is located.
        // Fourth, fifth and sixth digits (ZZZ) specify the branch location.
        return Validators.compose([
            Validators.required,
            UniversalValidators.isNumber,
            Validators.minLength(6),
            Validators.maxLength(6),
            UniversalValidators.noWhitespace
        ]);
    }

    public validateDateRangeSelections(formGroup) {
        let valid = false;

        // Check if dates use range or separate fields
        if (!formGroup.range) {
            // Dates
            const startDate = formGroup.startDate;
            const endDate = formGroup.endDate;

            let startDateValue;
            let endDateValue;

            // Format Dates
            if (startDate.length > 0) {
                startDateValue = startDate[0];
            }
            if (endDate.length > 0) {
                endDateValue = endDate[0];
            }

            if (startDateValue && endDateValue) {
                // Determine if startDate is before or same as endDate;
                const start = new Date(startDateValue);
                const end = new Date(endDateValue);
                valid = start <= end;
            } else {
                valid = true;
            }
        } else {
            valid = true;
        }

        return valid;
    }
}
