import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

import { LocalService } from './local.service';

@Injectable({ providedIn: 'root' })
export class CategoriesLocalService extends LocalService {
    constructor(public storage: Storage, public httpClient: HttpClient) {
        super(storage, httpClient);
    }

    nukeAllCategories() {
        this.key = 'genders';
        this.removeKey();
        this.key = 'media-media_categories';
        this.removeKey();
        this.key = 'age-media_categories';
        this.removeKey();
    }

    getGenders() {
        this.key = 'genders';
        return this.getValue().then(result => {
            return result;
        });
    }

    setGenders(data: any) {
        this.key = 'genders';
        return this.setValue(data).then(result => {
            return result;
        });
    }

    getMediaCategories() {
        this.key = 'media-media_categories';
        return this.getValue().then(result => {
            return result;
        });
    }

    setMediaCategories(data: any) {
        this.key = 'media-media_categories';
        return this.setValue(data).then(result => {
            return result;
        });
    }

    getAgeCategories() {
        this.key = 'age-media_categories';
        return this.getValue().then(result => {
            return result;
        });
    }

    setAgeCategories(data: any) {
        this.key = 'age-media_categories';
        return this.setValue(data).then(result => {
            return result;
        });
    }
}
