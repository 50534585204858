import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';

import { AuthCredentials } from '../../../models/auth-credentials.model';
import { FormHelperService } from '../../../providers/helpers/form-helper.service';
import { UserFeedbackService } from '../../../providers/helpers/user-feedback.service';
import { AuthService } from '../../../providers/services/auth.service';
import { SessionService } from '../../../providers/services/session.service';

@Component({
    selector: 'page-session-expired',
    templateUrl: 'session-expired-modal.html',
    styleUrls: ['session-expired-modal.scss']
})
export class SessionExpiredModalComponent {
    public loginForm: FormGroup;

    constructor(
        public navController: NavController,
        public modalController: ModalController,
        protected formHelper: FormHelperService,
        protected userFeedbackService: UserFeedbackService,
        protected authService: AuthService,
        protected sessionService: SessionService
    ) {
        this.loginForm = this.formHelper.getFormGroupForLoginPage();
    }

    login(model: any, isValid: boolean) {
        if (isValid) {
            this.userFeedbackService.presentLoading('Logging in', 30000);
            const authCredentials = new AuthCredentials(model.auth.email, model.auth.password);

            this.authService
                .login(authCredentials)
                .pipe(
                    finalize(() => {
                        this.userFeedbackService.dismissLoading();
                    })
                )
                .subscribe(
                    response => {
                        this.onLoginSuccess(response);
                    },
                    error => {
                        this.onLoginError(error);
                    }
                );
        }
    }

    onLoginSuccess(response) {
        // Save user to local storage and active session memory
        this.sessionService.initUserFromLogin(response).then(user => {
            // Dismiss the modal.
            this.dismiss();
        });
    }

    async dismiss() {
        await this.modalController.dismiss();
    }

    onLoginError(error) {
        this.userFeedbackService.presentToastError(error.message, false, true);
    }

    proceedAsGuest() {
        // Remove the current user from session
        const self = this;
        this.authService.logout().then(() => self.dismiss());
    }
}
