export const environment = {
    production: false,
    serverEndpoint: 'https://project.dnses.net/adfomo-dev',
    apiEndpoint: 'https://project.dnses.net/adfomo-api/public/api',
    environmentName: 'Development Environment',
    thumbnailerEndPoint: 'https://adfomo-whitelabel-test.s3.ap-southeast-2.amazonaws.com/',
    ionicEnvName: 'develop',
    analyticsAccount: 'UA-116136514-3',
    mapsStaticKey: 'AIzaSyBmPK8QI8MWgsVyd2Kd800evNCDpxBFUww',
    serviceWorker: false
};