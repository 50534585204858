import { Injectable } from '@angular/core';
import { CategoriesApiService } from '../api/categories.service';
import { CategoriesLocalService } from '../local/categories.service';
import { MultiSelectModule } from 'primeng/multiselect';
import { CategorySelectOptions } from './category-select-options';

@Injectable({ providedIn: 'root' })
export class CategoriesService {
    protected genders: any[];
    protected ageCategories: any[];
    protected mediaCategories: any[];

    public genderSelections: MultiSelectModule[] = [];
    public ageCategorySelections: MultiSelectModule[] = [];
    public mediaCategorySelections: MultiSelectModule[] = [];
    public mediaParentCategorySelections: MultiSelectModule[] = [];

    constructor(protected categoriesApiService: CategoriesApiService, protected categoriesLocalService: CategoriesLocalService) {}

    public getCategorySelections() {
        const categorySelections = new CategorySelectOptions();

        return Promise.all([
            this.getMediaCategorySelectOptions().then(selections => {
                return (categorySelections.mediaCategorySelectOptions = selections);
            }),
            this.getParentMediaCategorySelections().then(selections => {
                return (categorySelections.mediaParentCategorySelectOptions = selections);
            }),
            this.getAgeCategorySelectOptions().then(selections => {
                return (categorySelections.ageCategorySelectOptions = selections);
            }),
            this.getGenderSelectOptions().then(selections => {
                return (categorySelections.genderSelectOptions = selections);
            })
        ]).then(() => {
            return categorySelections;
        });
    }

    public getParentMediaCategorySelections() {
        return new Promise<any>((resolve, reject) => {
            if (this.mediaParentCategorySelections.length > 0) {
                resolve(this.mediaParentCategorySelections);
            } else {
                // Get them from the service
                return this.getMediaCategories().then(categories => {
                    const parentCategories = [];
                    for (const category of categories) {
                        if (category.parent_id === 0) {
                            parentCategories.push({
                                value: category.id,
                                label: category.name,
                                item_count: category.item_count,
                                parent_id: category.parent_id,
                                isParent: true
                            });
                        }
                    }

                    // Resolve them
                    resolve(parentCategories);
                });
            }
        });
    }

    public getMediaCategorySelectOptions() {
        return new Promise<any>((resolve, reject) => {
            // Get the selections if they exist in memory
            if (this.mediaCategorySelections.length > 0) {
                resolve(this.mediaCategorySelections);
            } else {
                // Get them from the service
                return this.getMediaCategories().then(categories => {
                    const selectionsUnsorted = [];
                    for (const category of categories) {
                        selectionsUnsorted.push({
                            value: category.id,
                            label: category.name,
                            item_count: category.item_count,
                            parent_id: category.parent_id,
                            isParent: category.parent_id === 0
                        });
                    }

                    const sorted = this.sortMediaCategoriesByParent(selectionsUnsorted);

                    // Set them in memory
                    this.mediaCategorySelections = sorted;

                    // Resolve them
                    resolve(sorted);
                });
            }
        });
    }

    public getMediaSubCategories(parent_id) {
        return new Promise<any>((resolve, reject) => {
            this.getMediaCategorySelectOptions().then(categories => {
                let subCategories = [];
                subCategories = categories.filter(item => {
                    return item.parent_id === parent_id;
                });
                resolve(subCategories);
            });
        });
    }

    protected sortMediaCategoriesByParent(selections) {
        const hashArr = {};

        for (let i = 0; i < selections.length; i++) {
            if (hashArr[selections[i].parent_id] === undefined) {
                hashArr[selections[i].parent_id] = [];
            }
            hashArr[selections[i].parent_id].push(selections[i]);
        }

        return this.hierarchySort(hashArr, 0, []);
    }

    protected hierarchySortByLabelFunction(a, b) {
        return a.label > b.label;
    }

    protected hierarchySort(hashArr, key, result) {
        if (hashArr[key] === undefined) {
            return;
        }

        // Only sort non-parent stuff
        const arr = hashArr[key];
        if (!hashArr[key][0].isParent) {
            arr.sort(this.hierarchySortByLabelFunction);
        }

        for (let i = 0; i < arr.length; i++) {
            result.push(arr[i]);
            this.hierarchySort(hashArr, arr[i].value, result);
        }

        return result;
    }

    public getAgeCategorySelectOptions() {
        return new Promise<any>((resolve, reject) => {
            // Get the selections if they exist in memory
            if (this.ageCategorySelections.length > 0) {
                resolve(this.ageCategorySelections);
            } else {
                // Get them from the service
                return this.getAgeCategories().then(categories => {
                    const selections = [];
                    for (const category of categories) {
                        selections.push({ value: category.id, label: category.label });
                    }

                    // Set them in memory
                    this.ageCategorySelections = selections;

                    // Resolve them
                    resolve(selections);
                });
            }
        });
    }

    public getGenderSelectOptions() {
        return new Promise<any>((resolve, reject) => {
            // Get the selections if they exist in memory
            if (this.genderSelections.length > 0) {
                resolve(this.genderSelections);
            } else {
                // Get them from the service
                return this.getGenders().then(categories => {
                    const selections = [];
                    for (const category of categories) {
                        selections.push({ value: category.id, label: category.label });
                    }

                    // Set them in memory
                    this.genderSelections = selections;

                    // Resolve them
                    resolve(selections);
                });
            }
        });
    }

    preloadCategoriesIntoMemory() {
        return Promise.all([this.getGenders(), this.getAgeCategories(), this.getMediaCategories()]);
    }

    purgeCategoriesFromMemory() {
        return Promise.all([(this.genders = null), (this.ageCategories = null), (this.mediaCategories = null)]);
    }

    purgeAllStorage() {
        this.categoriesLocalService.nukeAllCategories();
    }

    protected getMediaCategories() {
        return new Promise<any>((resolve, reject) => {
            const inMemory = this.mediaCategories;
            if (inMemory === undefined || inMemory == null || !inMemory) {
                return this.categoriesLocalService.getMediaCategories().then(localResult => {
                    if (localResult) {
                        this.persistMediaCategories(localResult);
                        resolve(localResult);
                    } else {
                        // Get from the server
                        return this.categoriesApiService
                            .getMediaCategories()
                            .toPromise<any>()
                            .then(remoteResult => {
                                if (remoteResult.data) {
                                    this.persistMediaCategories(remoteResult.data);
                                    resolve(remoteResult.data);
                                }
                            });
                    }
                });
            } else {
                resolve(inMemory);
            }
        });
    }

    protected getAgeCategories() {
        return new Promise<any>((resolve, reject) => {
            const inMemory = this.ageCategories;
            if (inMemory === undefined || inMemory == null || !inMemory) {
                return this.categoriesLocalService.getAgeCategories().then(localResult => {
                    if (localResult) {
                        this.persistAgeCategories(localResult);
                        resolve(localResult);
                    } else {
                        // Get from the server
                        return this.categoriesApiService
                            .getAgeCategories()
                            .toPromise<any>()
                            .then(remoteResult => {
                                if (remoteResult.data) {
                                    this.persistAgeCategories(remoteResult.data);
                                    resolve(remoteResult.data);
                                }
                            });
                    }
                });
            } else {
                resolve(inMemory);
            }
        });
    }

    protected getGenders() {
        return new Promise<any>((resolve, reject) => {
            const inMemory = this.genders;
            if (inMemory === undefined || inMemory == null || !inMemory) {
                return this.categoriesLocalService.getGenders().then(localResult => {
                    if (localResult) {
                        this.persistGenders(localResult);
                        resolve(localResult);
                    } else {
                        // Get from the server
                        return this.categoriesApiService
                            .getGenders()
                            .toPromise<any>()
                            .then(remoteResult => {
                                if (remoteResult.data) {
                                    this.persistGenders(remoteResult.data);
                                    resolve(remoteResult.data);
                                }
                            });
                    }
                });
            } else {
                resolve(inMemory);
            }
        });
    }

    // Genders

    protected persistGenders(genders) {
        this.categoriesLocalService.setGenders(genders);
        this.genders = genders;
    }

    // Age Categories

    protected persistAgeCategories(ageCategories) {
        this.categoriesLocalService.setAgeCategories(ageCategories);
        this.ageCategories = ageCategories;
    }

    // Media Categories

    protected persistMediaCategories(mediaCategories) {
        this.categoriesLocalService.setMediaCategories(mediaCategories);
        this.mediaCategories = mediaCategories;
    }
}
