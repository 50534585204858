import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { UserModelFactory } from '../../models/user-factory';
import { UserModel } from 'src/app/models/user.model';
import { UsersLocalService } from '../local/users.service';

@Injectable({ providedIn: 'root' })
export class SessionService {
    user$ = new BehaviorSubject<UserModel | boolean>(null);

    constructor(protected userLocalService: UsersLocalService) {}

    public watchUser(): Observable<UserModel | boolean> {
        return this.user$;
    }

    public peekUser(): UserModel | boolean {
        return this.user$.value;
    }

    public pokeUser(user: UserModel | boolean): void {
        this.user$.next(user);
    }

    public setUser(user: UserModel) {
        return this.userLocalService.set(user).then(() => {
            this.pokeUser(user);
        });
    }

    public removeUser(expired: boolean = false) {
        return this.userLocalService.removeKey().then(() => {
            this.pokeUser(expired);
        });
    }

    public initUserFromLogin(data) {
        const user: UserModel = new UserModelFactory().createUserFromLoginResponse(data);
        return this.setUser(user).then(() => {
            return user;
        });
    }

    public initUserFromImpersonate(data) {
        const user: UserModel = new UserModelFactory().createUserFromImpersonation(data);
        return this.setUser(user).then(() => {
            return user;
        });
    }

    public isLoggedIn() {
        // Returns a boolean indicating if a user is currently logged in.
        // Is status wrapper method for getUser which checks if a user exists in memory
        // and also validates the token in memory.
        // Note: Method call can cause user to be logged out if their token is invalid.
        return this.getCurrentUser()
            .then(user => {
                return typeof user === 'object' && user !== null;
            })
            .catch(reason => {
                return false;
            });
    }

    expireSession() {
        // This method removes the current user in memory and storage.
        this.removeUser(true);
    }

    getCurrentUser() {
        return new Promise<UserModel | boolean>((resolve, reject) => {
            // Check if current user is active
            const userInMemory = this.peekUser();
            if (typeof userInMemory !== 'object' || userInMemory === null) {
                // If not - check if user exists in local storage
                return this.userLocalService.get().then(user => {
                    if (user !== null) {
                        user = user as UserModel;
                        resolve(user);
                    } else {
                        reject('No user found in storage');
                    }
                });
            } else {
                resolve(userInMemory);
            }
        });
    }
}
