import { environment } from './../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';

import { SessionService } from '../services/session.service';

export class BaseApiService {
    constructor() {}

    buildUrl(resourcePath: string) {
        const apiEndpoint = environment.apiEndpoint;
        return apiEndpoint + '/' + resourcePath;
    }

    initialiseHeaders() {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return headers;
    }

    public handleRequestError(e, sessionService: SessionService) {
        // If the error can be read
        const error = e.error.error || e.error || e;

        // Catch 401 / token expired errors and log the user out!
        if (error.message === 'The token has expired') {
            sessionService.expireSession();
        }

        return throwError(error);
    }

    protected addTokenToHeaders(headers: HttpHeaders, token: string) {
        headers = headers.append('Authorization', 'Bearer ' + token);
        return headers;
    }
}
