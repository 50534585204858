import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({ providedIn: 'root' })
export class LocalService {
    path: string;
    key = 'LocalService';
    value: any;
    contentType: string;

    constructor(public storage: Storage, public httpClient: HttpClient) {}

    getValue() {
        return this.storage.get(this.key).then(value => {
            return JSON.parse(value);
        });
    }

    setValue(data) {
        return this.storage.set(this.key, JSON.stringify(data));
    }

    removeKey() {
        return this.storage.remove(this.key);
    }
}
