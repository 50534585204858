import { Component, ElementRef, Input } from '@angular/core';

import { MarkdownService } from './markdown.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'markdown, [markdown]',
    template: '<ng-content></ng-content>'
})
export class MarkdownComponent {
    private _data: string;

    @Input()
    get data(): string {
        return this._data;
    }
    set data(value: string) {
        this._data = value;
        this.render(value);
    }

    constructor(public element: ElementRef, public markdownService: MarkdownService) {}

    render(markdown: string) {
        this.element.nativeElement.innerHTML = this.markdownService.compile(markdown);
    }
}
