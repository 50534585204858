import { UserModel } from './user.model';
export class UserModelFactory {
    createUserFromLoginResponse(data: any) {
        const userInfo = data.user;
        const company = data.company;
        const token = data.token;

        const user = new UserModel(
            userInfo.id,
            userInfo.first_name,
            userInfo.last_name,
            userInfo.display_name,
            userInfo.email,
            userInfo.is_admin
        );

        // Add additional data
        user.token = token;
        user.isImpersonating = false;
        user.company_id = company.data.id;
        user.company_name = company.data.name;
        user.company_type = company.data.type;
        user.company_type_binary = company.data.type_binary;

        return user;
    }

    public createUserFromImpersonation(data: any) {
        const user = new UserModel(
            data.persona.id,
            data.persona.first_name,
            data.persona.last_name,
            data.persona.first_name + ' ' + data.persona.last_name,
            data.persona.email,
            data.persona.is_admin
        );

        user.token = data.token;
        user.isImpersonating = !!data.impersonator;
        user.company_id = data.company.id;
        user.company_name = data.company.name;
        user.company_type = data.company.type;
        user.company_type_binary = data.company.type_binary;

        return user;
    }
}
